<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [searchDisabled]="accountListMode == this.compViewMode.READONLY"
    [hideFilterButton]="hideFilterButton" [(searchText)]="accountSearchText" (onEnterClick)="onClickEnter($event)"
    (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onInput($event.detail,$event)"
    (onSearchFocus)="ionSearchFocus($event)" (onSearchBarClick)="clickSearchArea($event)"
    (onFilterClick)="openFilterMenu($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()"  (ionBlur)="onSearchBlur($event)">
  </ind-header-left>
  <!-- Key Controls Area -->
  <ind-key-controls-area id="allAccounts" *ngIf="accountsListKeyControlAreaModel" [viewData]="accountsListKeyControlAreaModel"> </ind-key-controls-area>
  <!-- Selected Suggestion -->
  <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
    <ion-icon name="ios-close-circle" (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
    <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
      {{suggestion.selectedFacet}}
      <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)" />
    </span>
  </div>
  <!-- Segment Tabs -->
  <ion-toolbar no-padding *ngIf="enableChangeRequestTab">
    <ind-tabs [data]="tabsData" [(selectedTab)]="accountsModel" (selectedTabChange)="checkAccountModel($event)">
    </ind-tabs>
  </ion-toolbar>
  <!-- ComponentChange Tabs -->
  <ion-toolbar no-padding *ngIf="hasComponentChangeTab">
    <ind-tabs [data]="tabsComponentData" [(selectedTab)]="componentModel" (selectedTabChange)="checkComponentModel($event)">
    </ind-tabs>
  </ion-toolbar>
  <!-- Selected Filter -->
  <div class="selectedSearchSuggestions" color="light" *ngIf="selectedFilterData && selectedFilterData.length">
    <span *ngFor="let filter of filterNameBubble; let i=index">
      <ion-text *ngIf="filter.isSavedFilter && i==0" class="category-name"> {{filter.savedFilterName}} : </ion-text>
      <ion-text *ngIf="!filter.isSavedFilter" class="category-name"> {{filter.categoryName}} : </ion-text>
      <span *ngFor="let facet of filter.suggestion" class="suggestionNameBubble">
        {{facet.selectedFacet}}
        <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedFilter(facet, filter)" />
      </span>
    </span>
  </div>
</ion-header>

<ion-content #content
  [ngClass]="{'vectorimageleft': showVectorImage(), 'show-progress-bar-pad': device.isBackgroundUploadInProgress && !accountsListKeyControlAreaModel}">

  <div [ngSwitch]="accountsModel">
    <div *ngSwitchCase="'myAccounts'"
      [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
      <ion-item-group>
        

        <!-- selected account section -->
        <ind-section-header
          *ngIf="accountService.selectedAccounts?.length > 0 && (accountListMode===compViewMode.SELECTION || accountListMode===compViewMode.ADDNEW || accountListMode===compViewMode.READONLY || accountService.isGlobalSearching)"
          [viewData]="selectedAccountsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
        </ind-section-header>

        <ion-list #selectedScroll scrollY="true" *ngIf="accountService.selectedAccounts?.length>0 && accountListMode!=compViewMode.LISTVIEW && !accountService.isGlobalSearching">
          <ion-item-group class="selector-item" *ngFor="let selAccount of accountService.selectedAccounts; let i = index;"
            (click)="accountListItemClicked(selAccount, $event)"
            [ngClass]="{'itemSelected':(selAccount?.id === (accountService?.accountPageMode != compViewMode.PREVIEW ? accountService?.selected?.id : accountService?.prevSelected?.id))}">
            <ion-item>
              <ion-item>
                <ion-row style="display: block;width: 100%;" [ngClass]="{'selected-item-group':opportunityStakeholderSelectionEnabled && (accountService.accessedAccountListFrom == pagename.OpportunityDetailsComponent || accountService.accessedAccountListFrom == pagename.NewOpportunityComponent)}">
                  <ion-col>              
                    <span class="accountSpan">{{selAccount.accountName}} {{(selAccount.status === 2) ?' (Inactive)' : ''}}
                      <div *ngIf="accountService.accessedAccountListFrom != pagename.OpportunityDetailsComponent" class="secondaryInfo overflowHiddenAndEllipsis secondaryTextColorAndfont">{{getSecondaryInfo(selAccount)}}</div>
                    </span>
                  </ion-col>
                  <ion-col>
                    <div class="selection_container" *ngIf="opportunityStakeholderSelectionEnabled && (accountService.accessedAccountListFrom == pagename.OpportunityDetailsComponent || accountService.accessedAccountListFrom == pagename.NewOpportunityComponent)">
                      <div>{{transactionCurrencySymbol}}<input class="stakeholder-input" *ngIf="isOpportunityStakeholderValueEnabled" type="number" placeholder="{{'ENTER_ACCOUNT_VALUE' | translate}}" [(ngModel)]="selAccount.stakeholderValue" (input)="handleStakeholderValueInput($event)"></div>
                      <div *ngFor="let role of opportunityService.stakeholderRoles">
                        <div class="channel-item-wrapper" #roundInput class="round" (click)="updateConnectionRoleToSelectedAccount($event, selAccount, role)">
                          <input type="checkbox" [ngModel]="slectedAccountConnectionRole(selAccount, role)?.isSelected" />
                          <label [for]="role.connectionroleid" [ngClass]="{'selected':slectedAccountConnectionRole(selAccount, role)?.isSelected}"></label>
                          <span style="margin-bottom:15px; margin-left: 15px; overflow-wrap: anywhere;" size="25">{{role.name}}</span>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-icon slot="end" class='remove-icon'
                ngClass="{{ disableRemoveIcon(selAccount.id) ? 'disabled-icon': '' }}"
                *ngIf="(accountService.accessedAccountListFrom != 205 && (accountListMode===compViewMode.SELECTION || accountListMode == compViewMode.ADDNEW || accountService.isGlobalSearching))"
                (click)="onAccountSelected(selAccount, false)">
              </ion-icon>
            </ion-item>
            <div class="separationLine"></div>
          </ion-item-group>
        </ion-list>

        <!-- account section for global searched and selected accounts-->
        <ion-list #selectedScroll scrollY="true" *ngIf="accountService.selectedAccounts?.length>0 && accountService.isGlobalSearching">
          <ion-item-group class="selector-item" *ngFor="let selAccount of accountService.selectedAccounts; let i = index;">
            <ion-item>
              <ion-item>
                <ion-row style="display: block; width: 100%;">
                  <ion-col>
                    <ion-label class="accountSpanGlobalSearch" (click)="globalSearchedAccountListItemClicked(selAccount)">
                      <span>{{selAccount.accountName}} {{(selAccount.status === 2) ?' (Inactive)' : ''}}
                        <div class="overflowHiddenAndEllipsis secondaryTextColorAndfont">{{getSecondaryInfo(selAccount)}}</div>
                      </span>
                    </ion-label>
                  </ion-col>
                  <ion-col>
                    <div class="selection-container-global-search" *ngIf="selectablLinkedEntityData && selectablLinkedEntityData.length>0">
                      <div *ngFor="let selLinkedEntity of selectablLinkedEntityData">
                        <div class="sub-selectable-item-wrapper" #roundInput [ngClass]="{'round': !selLinkedEntity.isDisableChkBox,'round-disabled': selLinkedEntity.isDisableChkBox || device.isOffline}" (click)="selectLinkedEntity(selLinkedEntity)">
                          <input type="checkbox" disabled [ngModel]="selLinkedEntity.isChecked" />
                          <label [for]="selLinkedEntity.selId" [ngClass]="{'selected':selLinkedEntity.isChecked, 'display-none':selLinkedEntity.isDisableChkBox}"></label>
                          <span style="margin-left: 15px; overflow-wrap: anywhere;" size="25">{{selLinkedEntity.selName}}</span>
                        </div>
                        <div *ngIf="selLinkedEntity?.approvalStatus" class="approvalStatusInfo">
                          <span>{{selLinkedEntity?.approvalStatus}}</span>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-icon slot="end" class='remove-icon' [ngClass]="selectablLinkedEntityData && selectablLinkedEntityData.length>0 ? hasSecondaryInfo ? 'remove-icon-gsa-with-secInfo':'remove-icon-gsa' : ''" (click)="onAccountSelected(selAccount, false)"></ion-icon>
            </ion-item>
            <div class="separationLine"></div>
          </ion-item-group>
        </ion-list>

        <ind-section-header
          *ngIf="accountService.selectedOKAccounts?.length > 0 && (accountListMode===compViewMode.ADDNEW || accountListMode===compViewMode.READONLY || accountService.isOneKeySearching)"
          [viewData]="selectedOKAccountsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
        </ind-section-header>

        <ion-list #selectedScroll scrollY="true"
          *ngIf="accountService.selectedOKAccounts?.length>0 && (accountListMode!=compViewMode.LISTVIEW || accountService.isOneKeySearching)">
          <ion-item-group class="selector-item"
            *ngFor="let selAccount of accountService.selectedOKAccounts; let i = index;"
            (click)="displayOKDetails(selAccount)"
            [ngClass]="{'itemSelected':(selAccount['workplaceEid']===accountService?.selectedOKAccount['workplaceEid'])}">
            <ion-item>
              <ion-icon slot="end" class='remove-icon'
                *ngIf="(accountListMode == compViewMode.ADDNEW || accountService.isOneKeySearching)"
                (click)="onOKAccountSelected(selAccount, false)">
              </ion-icon>
              <span class="accountSpan">{{accountService.getAccountName(selAccount)}}
              </span>
            </ion-item>
            <div class="separationLine"></div>
          </ion-item-group>
        </ion-list>

        <!--Near by accounts--> 
        <ind-section-header
          *ngIf="accountService.accessedAccountListFrom == 205"
          [viewData]="nearByAccountsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
        </ind-section-header>

        <ion-list *ngIf="accountService.accessedAccountListFrom == 205">
          <ion-item-group id="{{account.id}}" class='selector-item' *ngFor="let account of nearByAccountsToDisplay"
            [ngClass]="{'itemSelected':(account?.id === accountService?.selected?.id)}">
            <ion-item>
              <account-list-item [showTertiary]="showTertiaryFlag" [account]="account" [showChildren]="false"
                [expanded]='expandAll' [showCheckboxForTag]="isAccountTagSelectEnabled" [expandedChildren]='expandAll'
                [accountListMode]="getViewModeForAccountItem(account)"
                (OnAccountSelected)="onAccountSelected($event, $event.isSelected)"
                (OnCheckAccountForTag)="onTagCheckboxValueChange($event)"
                (OnAccountListItemClicked)="accountListItemClicked($event)" [sortBy]="sortBy"></account-list-item>
            </ion-item>
            <div class="separationLine"></div>
          </ion-item-group>
        </ion-list>
        <p *ngIf='!nearByAccountsToDisplay?.length && accountService.accessedAccountListFrom == 205' class="no-data-message"> {{'NO_NEAR_BY_ACCOUNTS' | translate}}</p>
        
        <!-- Adding this block to display the added/updated accounts on clicking from toolsdrawer -->
        <!-- new accounts section -->
        <ind-section-header
          *ngIf="newAccounts.length && accountListMode!==compViewMode.ADDNEW && accountListMode!==compViewMode.READONLY && !accountService.isOneKeySearching && !searching && !filtering"
          [viewData]='{id:"accounts-new",title: translate.instant("ACCOUNT_NEW_ACCOUNTS")+" ("+newAccounts.length+")"}'>
        </ind-section-header>



        <ion-list #selectedScroll scrollY="true"
          *ngIf="newAccounts?.length>0 && accountListMode!==compViewMode.ADDNEW
                                              && accountListMode!==compViewMode.READONLY && !accountService.isOneKeySearching && !searching && !filtering">
          <ion-item-group *ngFor="let selAccount of newAccounts" (click)="accountListItemClicked(selAccount)"
            [ngClass]="{'itemSelected':(selAccount?.id === (accountService?.accountPageMode != compViewMode.PREVIEW ? accountService?.selected?.id : accountService?.prevSelected?.id))}">
            <ion-item>
              <span class="accountSpan">{{selAccount.accountName}}
                <div class="overflowHiddenAndEllipsis secondaryTextColorAndfont">{{getSecondaryInfo(selAccount)}}</div>
              </span>
            </ion-item>
            <div class="separationLine"></div>
          </ion-item-group>
        </ion-list>

        <!--Affiliated accounts from selected contacts from meeting-->
        <ind-section-header
          *ngIf="!searching && !filtering && (accountListMode!=compViewMode.READONLY) && affiliatedAccounts.length !==0"
          [viewData]='{id:"accounts-affiliated",title: translate.instant("ACCOUNT_AFFILIATED_ACCOUNTS")+" ("+affiliatedAccounts?.length+")"}'>
        </ind-section-header>


        <ion-list *ngIf="!searching && !filtering && (accountListMode!=compViewMode.READONLY) && affiliatedAccounts.length !==0"
          [ngStyle]="{'display': (searching || filtering ? 'none' : 'block')}">
          <ion-item-group *ngFor="let affiliatedAccount of affiliatedAccounts" class='selector-item'
            [ngClass]="{'itemSelected':(affiliatedAccount?.id === (accountService?.accountPageMode != compViewMode.PREVIEW ? accountService?.selected?.id : accountService?.prevSelected?.id))}">
            <ion-item>
              <account-list-item [account]="affiliatedAccount" [accountIdfromIndDynamic]="accountIdfromIndDynamic"
                [showChildren]="true" [expanded]='expandAll' [expandedChildren]='expandAll'
                [accountListMode]="getViewModeForAccountItem(affiliatedAccount)" (OnAccountSelected)="onAccountSelected($event, $event.isSelected)"
                (OnAccountListItemClicked)="accountListItemClicked($event)"></account-list-item>
            </ion-item>
            <div class="separationLine"></div>
          </ion-item-group>
        </ion-list>

        <!-- All Accounts -->
        <ng-container *ngIf="!searching && !filtering">

          <ind-section-header *ngIf="(accountListMode!=compViewMode.READONLY)" [viewData]="allAccountsHeaderModel"
            (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
          <ion-list *ngIf="(accountListMode!=compViewMode.READONLY)"
            [ngStyle]="{'display': (searching || filtering ? 'none' : 'block')}">
            <ion-item-group id="{{account.id}}" class='selector-item' *ngFor="let account of accounts"
              [ngClass]="{'itemSelected':(account?.id === (accountService?.accountPageMode != compViewMode.PREVIEW ? accountService?.selected?.id : accountService?.prevSelected?.id))}">
              <ion-item>
                <account-list-item [showTertiary]="showTertiaryFlag" [account]="account"
                  [accountIdfromIndDynamic]="accountIdfromIndDynamic" [showChildren]="true" [expanded]='expandAll' [showCheckboxForTag]="isAccountTagSelectEnabled"
                  [expandedChildren]='expandAll' [accountListMode]="getViewModeForAccountItem(account)"
                  (OnAccountSelected)="onAccountSelected($event, $event.isSelected)"
                  (OnCheckAccountForTag)="onTagCheckboxValueChange($event)"
                  (OnAccountListItemClicked)="accountListItemClicked($event)" [sortBy]="sortBy"></account-list-item>
              </ion-item>
              <div class="separationLine"></div>
            </ion-item-group>
          </ion-list>
          <p *ngIf='!accounts?.length' class="no-data-message"> {{'NO_ACCOUNTS' | translate}}</p>
          <ion-infinite-scroll *ngIf="(accountListMode!=compViewMode.READONLY)"
            (ionInfinite)="doInfinite($event.detail,$event)" threshold="20%">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ng-container>

        <!-- Search account results -->
        <ng-container *ngIf="(searching || filtering) && !accountService.isGlobalSearching && !accountService.isOneKeySearching">
          <ind-section-header *ngIf="(searching || filtering) && (accountListMode!=compViewMode.READONLY)"
            [viewData]="resultsAccountsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
          </ind-section-header>
          <ion-list>
            <ion-item-group id="{{account.id}}" class='selector-item' *ngFor="let account of filteredList"
              [ngClass]="{'itemSelected':(account?.id === (accountService?.accountPageMode != compViewMode.PREVIEW ? accountService?.selected?.id : accountService?.prevSelected?.id))}">
              <ion-item>
                <account-list-item [showTertiary]="showTertiaryFlag" [account]="account"
                  [accountIdfromIndDynamic]="accountIdfromIndDynamic" [showChildren]="true" [expanded]='expandAll' [showCheckboxForTag]="isAccountTagSelectEnabled"
                  [expandedChildren]='expandAll' [accountListMode]="getViewModeForAccountItem(account)"
                  (OnAccountSelected)="onAccountSelected($event, $event.isSelected)"
                  (OnCheckAccountForTag)="onTagCheckboxValueChange($event)"
                  (OnAccountListItemClicked)="accountListItemClicked($event)" [sortBy]="sortBy"></account-list-item>
              </ion-item>
              <div class="separationLine"></div>
            </ion-item-group>
            <ind-no-search-result *ngIf="filteredList.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)">
            </ind-no-search-result>
          </ion-list>
        </ng-container>

        <ng-container *ngIf="accountService.isGlobalSearching">
          <ind-section-header *ngIf="accountService.isGlobalSearching && (accountListMode!=compViewMode.READONLY)"
            [viewData]="globalSearchedAccountsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
          </ind-section-header>
          <ion-list>
            <ion-item-group id="{{account.id}}" class='selector-item'
              *ngFor="let account of accountService.globalSearchResults"
              [ngClass]="{'itemSelected':(account?.id === (accountService?.accountPageMode != compViewMode.PREVIEW ? accountService?.selected?.id : accountService?.prevSelected?.id))}">
              <ion-item>
                <account-list-item [showTertiary]="showTertiaryFlag" [accountIdfromIndDynamic]="accountIdfromIndDynamic"
                  [account]="account" [showChildren]="false" [accountListMode]="compViewMode.SELECTION"
                  (OnAccountSelected)="onAccountSelected($event, $event.isSelected)"
                  (OnAccountListItemClicked)="globalSearchedAccountListItemClicked($event)" [sortBy]="sortBy">
                </account-list-item>
              </ion-item>
              <div class="separationLine"></div>
            </ion-item-group>
            <ind-no-search-result *ngIf="accountService.globalSearchResults.length === 0"
              [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
          </ion-list>
        </ng-container>

        <ng-container *ngIf="accountService.isOneKeySearching">
          <ind-section-header *ngIf="(accountListMode!=compViewMode.READONLY)"
            [viewData]="oneKeySearchedAccountsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
          </ind-section-header>
          <ion-list>
            <ion-item-group class='selector-item' *ngFor="let account of onekeyApiService.oneKeyAccounts"
              [ngClass]="{'itemSelected':(accountService?.selectedOKAccount ? account['workplaceEid']===accountService?.selectedOKAccount['workplaceEid'] : false)}">
              <ion-item>
                <span class="item-span-label primary-text" (click)="displayOKDetails(account)"
                  tappable>{{accountService.getAccountName(account)}}
                </span>
                <ion-icon slot="end" class='add-icon'
                  *ngIf="enableOneKeyDownload && !account['isInContract'] && !account['isSelected']"
                  (click)="addToOKSelected(account)"></ion-icon>
                <ion-icon class='checkmark-icon' slot="end" *ngIf="account['isSelected']"></ion-icon>
              </ion-item>
              <div class="separationLine"></div>
            </ion-item-group>
            <ind-no-search-result *ngIf="onekeyApiService.oneKeyAccounts.length === 0"
              [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
          </ion-list>
        </ng-container>
      </ion-item-group>
    </div>
    <div *ngSwitchCase="'changeRequests'">
      <ind-section-header [viewData]="myAccountChangeRequestsHeaderModel"
        (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <div *ngIf="!accountSearchText && !filtering && filteredAccountCRs.length === 0" class="no-data-message">{{'NO_CHANGE_REQUESTS'
        | translate}}</div>
      <ind-no-search-result *ngIf="(accountSearchText || filtering) && filteredAccountCRs.length === 0"
        [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
      <account-cr [source]="filteredAccountCRs"></account-cr>
    </div>
  </div>
</ion-content>

<footer-toolbar [footerView]="'masterView'" [selectedView]="'accounts'"></footer-toolbar>

<search-suggestion-popover (click)="clickedInSuggestionsArea($event)"
  (asessmentSelectionMade)="handleAssessmentSearchClick($event)" (selectionMade)="handleFacetSelection($event)" (selectTag)="handleTagSelection($event)"
  [suggestionsData]="suggestionsData" [searchKey]="searchKey"
  [listPeriodTags]="listPeriodTags" (selectListPeriodTag)="handleListPeriodTagSelection($event)"
  *ngIf="suggestionsActive && !(accountService.accessedAccountListFrom == 205 || accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage')"
  (selectSavedSearch)="handleSavedSearchSelection($event)" [from]="uiService.page.AccountPageComponent">
</search-suggestion-popover>
